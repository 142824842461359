
import { defineComponent, computed } from 'vue';

export default defineComponent({
    props: {
        activeBtn: {
            type: Number,
            required: true
        },
        editMode: {
            type: Boolean,
            default: false
        },
        amountOrder: {
            type: Number,
            default: 0
        },
        totalInvoices: {
            type: Number,
            default: 0
        },
        totalAllReceipts: {
            type: Number,
            default: 0
        },
        totalBindedReceipts: {
            type: Number,
            default: 0
        },
        totaInvoiceslLeftToBind: {
            type: Number,
            default: 0
        },
        balance: {
            type: Number,
            default: 0
        },
        currencySign: {
            type: String,
            default: ''
        }
    },
    async setup(props, { emit }) {
        function back() {
            emit('back');
        }
        function edit() {
            emit('edit');
        }
        const hideMobile = computed(() => props.activeBtn == 3 || props.activeBtn == 4);
        return { back, edit, hideMobile };
    }
});
