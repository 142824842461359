
import { defineComponent, ref, computed, toRef } from 'vue';
import BusinessClientDetails from '@/components/BusinessClients/BusinessClientDetails.vue';
import BusinessClientFooter from '@/components/BusinessClients/BusinessClientFooter.vue';
import BusinessClientHeader from '@/components/BusinessClients/BusinessClientHeader.vue';
import MobileFooterActions from '@/components/MobileFooterActions.vue';
import swal from 'sweetalert2';
import { BusinessClient } from '@/models/BusinessClients';
import { api } from '@/services/Api';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import { useRoute } from 'vue-router';

export default defineComponent({
    name: 'BusinessClientView',
    components: {
        BusinessClientDetails,
        BusinessClientFooter,
        BusinessClientHeader,
        MobileFooterActions
    },
    props: {
        id: {
            type: Number,
            default: 0
        },
        page: {
            type: Number,
            default: 1
        }
    },
    async setup(props) {
        const activeBtn = toRef(props, 'page');
        const loaded = ref(false);
        const { t } = useI18n();
        const businessClient = ref<BusinessClient>(new BusinessClient());
        const showFooterActions = computed(() => activeBtn.value == 1 || activeBtn.value == 2);
        const amountOrder = ref(0);
        const totalInvoices = ref(0);
        const totalAllReceipts = ref(0);
        const totalBindedReceipts = ref(0);
        const totaInvoiceslLeftToBind = ref(0);
        const balance = ref(0);
        const currencySign = ref('');
        if (props.id) {
            const response = await api.getBusinessClientDetails(props.id);
            if (response.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
                return;
            }
            if (response.data) {
                businessClient.value = response?.data.businessClient || new BusinessClient();
                amountOrder.value = response.data.amountOrder;
                totalInvoices.value = response.data.totalInvoices;
                totalAllReceipts.value = response.data.totalAllReceipts;
                totalBindedReceipts.value = response.data.totalBindedReceipts;
                totaInvoiceslLeftToBind.value = response.data.totaInvoiceslLeftToBind;
                balance.value = response.data.balance;
                currencySign.value = response.data.currencySign;
            }
            loaded.value = true;
        }
        function back() {
            router.go(-1);
        }
        function edit() {
            router.push({ name: 'businessClientEdit', params: { id: props.id, page: activeBtn.value } });
        }

        function setActiveBtn(activeBtnValue: number) {
            router.push({ name: 'businessClientView', params: { id: props.id, page: activeBtnValue } });
        }
        return {
            businessClient,
            back,
            edit,
            loaded,
            setActiveBtn,
            activeBtn,
            showFooterActions,
            amountOrder,
            totalInvoices,
            totalAllReceipts,
            totalBindedReceipts,
            totaInvoiceslLeftToBind,
            balance,
            currencySign
        };
    }
});
